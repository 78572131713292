import React, { useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Container,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import SecurityIcon from "@mui/icons-material/Security";
const MContainer = styled(Container)(({ theme }) => ({
  color: "white",
  paddingTop: theme.spacing(10),
  backgroundColor: theme.palette.primary.main,
  position: "sticky",
  top: 0,
  [theme.breakpoints.up("sm")]: {
    backgroundColor: "white",
    color: "#555",
    border: "0px solid #ece7e7",
  },
}));

const paperStyle = {
  padding: 20,
  height: "275",
  width: 350,
  margin: "20px auto",
};
const avatarStyle = { backgroundColor: "#e69138" };
const btnstyle = { margin: "8px 0" };

const SecurityChallenge = () => {
  const params = useParams();
  const [formData, setFormData] = useState({
    id: params.id,
    code: "",
  });

  const { id, code } = formData;

  const url = process.env.REACT_APP_API_URL;

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [mesg, setMesg] = useState("");


  const setAlertMesg = (open, mesg) => {
    setMesg(mesg);
    setOpen(open);
  }


  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMesg(false, "");

  };

  const onChange = (e) => {
    
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData)
    axios.post(url+'/p/challenge', formData).then((response) => {
      console.log(response)
      navigate(`/message/${params.id}`, {
        state: {
          message: 'Challenge succeeded. Please wait for text with further infomation.'
        },
      });
  }).catch((error) => {
      console.log(error)
      navigate(`/message/${params.id}`, {
        state: {
          message: 'An error has occur. Please try again or contact the buisness host or attempt the challenge again.' + error.response.data.message 
        },
      });
  });
    

  };

  const handleResendChallenge = (event) => {
    console.log(`handleResendChallenge: ${id}`)

  }


  return (
    <Container maxWidth="sm">
      <Grid>
        {/*<Paper elevation={10} style={paperStyle}> */}
          <Grid align="center">
            <Avatar style={avatarStyle}>
              <SecurityIcon />
            </Avatar>
            <h2>Security Challenge</h2>
            Enter the 6 digit code that was sent to your mobile text message.
          </Grid>
          
            <TextField
              required
              id="standard-required1"
              name="mobile"
              label="unique key"
              placeholder="Mobile"
              value={id}
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
              InputProps={{
                readOnly: true,
              }}
            />
            <br></br>
            <TextField
              required
              id="standard-required"
              name="code"
              label="Security Code"
              placeholder="Enter 6 character code"
              value={code}
              type="text"
              fullWidth
              margin="normal"
              size="small"
              onChange={onChange}
            />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
              onClick={handleSubmit}
            >
              Authenticate Code
            </Button>
          
          
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
              onClick={handleResendChallenge}
              disabled
            >
              Resend Code
            </Button>
          
          <Typography>
            {" "}
            Check your mobile text message for security code.
          </Typography>
        {/*</Paper> */}
      </Grid>

      <Snackbar open={open} autoHideDuration={15000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="warning"
          sx={{ width: "500px" }}
        >
          {mesg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SecurityChallenge;
