import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const paperStyle = {
  padding: 20,
  height: "275",
  width: 300,
  margin: "20px auto",
};
const avatarStyle = { backgroundColor: "#1bbd7e" };
const btnstyle = { margin: "8px 0" };

const SelfAdd = () => {
  const navigate = useNavigate();
  const params = useParams();
  const url = process.env.REACT_APP_API_URL;
  const [data, setData] = useState({
    qrcode: params.id,
    name: "",
    guestNumber: "",
    note: "",
    mobile: "",
    label: ""
  });
  const { name, guestNumber, note, mobile } = data;
  const [label, setLabel] = React.useState('');
  const [labeldata, setLabeldata] = React.useState(['default'])



  React.useEffect(() => {
    console.log(`id :  ${params.id}`)
    axios.get(url + `/p/qrcode/${params.id}`)
      .then((data) => {
        setLabeldata(data.data)
        console.log(data.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])


  const handleChange = (event) => {
    console.log(event.target.value)
    setLabel(event.target.value);
    setData({ ...data, 'label': event.target.value });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(data);
    axios.post(url + '/p/party', data)
      .then((response) => {
        console.log(response)
        navigate(`/challenge/${params.id}-${mobile}`, {
          state: {
            key: mobile,
          },
        });
      })
      .catch((error) => {

        console.log(error.response.data.message)
        navigate(`/message/${params.id}-${mobile}`, {
          state: {
            message: 'An error has occur. Please try again or contact the buisness host.' + error.response.data.message
          },
        });
      }
      );
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box sx={{ height: "100vh" }}>
          <Grid>
            {/* <Paper elevation={10} style={paperStyle}> */}
            <Grid align="center">
              <Avatar style={avatarStyle}>
                <GroupAddIcon />
              </Avatar>
            </Grid>
            <Typography variant="h4">Self Service Waitlist</Typography>
            <Typography>
              This is a waitlist signup provided by&nbsp;
              <a href="https://waitabout.com" target='_blank' rel='noreferrer'>
               waitabout
              </a> from the QR/URL that you followed. 
              Add your party to the waitlist by entering your details below. A 6 digit code will be sent to
              your mobile and will need to be enter on the next page to confirm your signup. 
              The establishment for this waitlist will text you when they are ready for you.
              Message and data rates may apply.
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              value={name}
              label="Full Name"
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              onChange={onChange}
            />
            <p></p>
            <TextField
              autoFocus
              margin="dense"
              id="mobile"
              name="mobile"
              value={mobile}
              label="Mobile (xxxyyyzzzz)"
              type="number"
              fullWidth
              variant="outlined"
              size="small"
              onChange={onChange}
            />
            <p></p>
            {labeldata.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Service Selection</InputLabel>
                <Select
                  labelId="label"
                  id="label"
                  value={label}
                  label="label"
                  onChange={handleChange}
                  size="small"
                >
                  {

                    labeldata.map(
                      (label) => (
                        <MenuItem value={label.name}>{label.name}</MenuItem>
                      )

                    )
                  }

                </Select>
              </FormControl>) : (<div></div>)
            }
            <p></p>
            <TextField
              autoFocus
              margin="dense"
              id="guestNumber"
              name="guestNumber"
              value={guestNumber}
              label="Number of Guests"
              type="number"
              size="small"
              variant="outlined"
              onChange={onChange}
            />

            <div></div>
            <p></p>
            <TextField
              autoFocus
              id="note"
              label="Notes"
              name="note"
              value={note}
              multiline
              rows={3}
              fullWidth
              sx={{ marginTop: "10px" }}
              onChange={onChange}
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>
            {/* </Paper> */}
          </Grid>
          <a href="https://waitabout.com/policy" target='_blank' rel='noreferrer'>
            Privacy
        </a> <br></br>
        <a href="https://waitabout.com/terms" target='_blank' rel='noreferrer'>
            Terms
        </a>
        <br></br>
        Contact the owner of the waitlist or support@waitabout.com for help. Type STOP to remove your self from this service. 
        You will no longer be able to use this service with any provider that uses waitabout for their waitlist system.
        </Box>



      </Container>
    </React.Fragment>
  );
};

export default SelfAdd;
