import { Box, Container, CssBaseline, Typography } from '@mui/material';
import React from 'react'
import { useLocation } from 'react-router-dom';

const Message = ({message}) => {

  const params = useLocation();
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box sx={{  height: '100vh' }} >
        <Typography variant="body1">{message} {params.state? params.state.message : ''}</Typography>

        </Box>
      </Container>
    </React.Fragment>);
  
}

export default Message