import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Button, Container, Typography } from '@mui/material';
import {  useLocation, useNavigate, useParams } from "react-router-dom";
const btnstyle = { margin: "8px 0" };
export const Uid = () => {
    const [waitData, setWaitData] = useState({});
    const params = useParams();
    const url = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    useEffect(()=> {
        console.log(params.id)

        const fetch = async() => {
            try {
                const data = await axios.get(url+`/p/uid/${params.id}`)
                console.log(data)
                setWaitData(data.data);
            } catch(error) {
                console.error(error);
            }
        } 
        fetch()

    }, [])
    
    const handleSubmit = (event) => {
        event.preventDefault();

        axios.delete(url+'/p/uid/'+params.id, null).then((response) => {
          console.log(response)
          navigate(`/message/${params.id}`, {
            state: {
              message: 'Cancel succeeded. '
            },
          });
      }).catch((error) => {
          console.log(error)
          navigate(`/message/${params.id}`, {
            state: {
              message: 'An error has occur. Please try again or see the host.' + error.response.data.message 
            },
          });
      });
    }

    
    return (
        
        <Container maxWidth="sm">
            <br></br><br></br>
            <Typography sx={{ fontSize: 18 }} color="text.primary">You are in position {waitData.position} in line</Typography>
            <Typography sx={{ fontSize: 18 }} color="text.primary">Checkedin at {new Date(waitData.time * 1000).toLocaleString()}</Typography>
            <Typography sx={{ fontSize: 18 }} color="text.primary">Average wait: {waitData.avgWaittime} mins</Typography>
            <br></br><br></br><br></br><br></br>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
              onClick={handleSubmit}
            >
              Cancel Appointment
            </Button>
        </Container>
        
    );
}
