import "./App.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { Uid } from "./component/Uid";
import { Home } from "./component/Home";
import SelfAdd from "./component/SelfAdd";
import Message from "./component/Message";
import SecurityChallenge from "./component/SecurityChallenge";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route  path="/" element={<Home />}>
            <Route path="uid" element={<Uid />}>
              <Route path=":id" element={<Uid />} />
            </Route>
            <Route path="add" element={<SelfAdd />}>
              <Route path=":id" element={<SelfAdd />} />
            </Route>
            <Route path="challenge" element={<SecurityChallenge />}>
              <Route path=":id" element={<SecurityChallenge />} />
            </Route>
            <Route path="message" element={<Message />} >
              <Route path=":id" element={<Message />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
